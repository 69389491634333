@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.title {
  margin: 0;
  padding: 16px 0;
  font-weight: 400;
  font-size: 16px;
  color: @black85;

  @media (max-width: @desktop-min) {
    display: none;
  }
}

.list {
  max-height: 60vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 16px 0;
  overflow-y: auto;

  @media (max-width: @desktop-min) {
    max-height: none;
  }
}