@import (reference) "~@tehzor/ui-components/src/styles/variables";

.wrapper {
  padding: 70px 0 0 0;

  @media (max-width: @desktop-min) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;
  }
}