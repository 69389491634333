@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: @desktop-min) {
    height: 100%;
    justify-content: center;
  }
}

.icon {
  padding: 32px 0 0 0;
  font-size: 88px;
  color: @greyLight;

  @media (max-width: @desktop-min) {
    padding: 0;
  }
}

.text {
  margin: 0;
  padding: 8px 0 16px;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  color: @grey;
}