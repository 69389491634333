@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

.space-indicators {
	&__wrapper {
		width: 72px;
		display: flex;
		flex-direction: row;
		gap: 4px;
		height: 10px;
		justify-content: center;
		align-items: center;
		margin-bottom: 5px;
	}
	&__tags {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	&__tag {
		margin: 2px 2px;
		width: 5px;
		height: 5px;
	}
	&__rest {
		.d-semibold-12();
		color: @deepBlue;
	}
}
