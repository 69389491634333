@import (reference) "~@tehzor/ui-components/src/styles/colors";

.wrapper {
	padding: 24px 0 17px 0;
	border-bottom: 1px solid @black10;
}

.button {
	width: 100%;
}

.dialog {
	width: calc(100% - 32px);
}