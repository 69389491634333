.diagram {
	width: 72px;
	height: 80px;
	cursor: pointer;
	transition: 0.3s ease;

	&:hover {
		transform: scale(1.17);
	}

	&-disabled {
		.diagram__shadow {
			display: none;
		}

		&:hover {
			transform: scale(1);
		}
	}

	svg {
		overflow: visible;
	}

	&:hover &__shadow {
		opacity: 1;
	}

	&__shadow {
		opacity: 0;
		background-color: transparent;
		filter: drop-shadow(0px 8px 12px rgba(31, 52, 79, 0.20));
		transition: 0.2s ease;
	}
}

.wrapper {
	width: 72px;
	height: 80px;
	cursor: pointer;
	transition: 0.3s ease;

	&:hover {
		transform: scale(1.17);
	}

	&:hover .shadow {
		opacity: 1;
	}
}

svg {
	overflow: visible;
}

.wrapperDisabled {
	pointer-events: none;

	.shadow {
		display: none;
	}

	&:hover {
		transform: scale(1);
	}
}

.shadow {
	opacity: 0;
	background-color: transparent;
	filter: drop-shadow(0px 8px 12px rgba(31, 52, 79, 0.20));
	transition: 0.2s ease;
}

.innerCell {
	opacity: 0.26;
}
