@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

.spaces-board {
	display: flex;
	align-items: stretch;
	position: relative;
	width: 100%;
	background: @white;

	&__building {
		flex: 1 0;
		padding: 8px 0 0;
	}

	&__header {
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;
		gap: 16px;
	}

	&__title {
		color: @black75;
		.d-semibold-16();
	}

	&__floor {
		display: flex;
		align-items: flex-start;

		&:not(:last-child) {
			border-bottom: 1px solid @grey10;
		}

		&-num {
			width: 32px;
			text-align: center;
			padding-top: 18px;
			color: @greyLight;
			.tm-title-bold-24();

			&-wrap {
				margin-right: 8px;
			}
		}

		&-column {
			flex-direction: column;
			flex-wrap: wrap;
		}

		&-column &-num-wrap {
			padding: 23px 0;
		}

		&-column &-num {
			width: 100%;
			padding-top: 0;
		}
	}

	&__spaces {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		flex: 1 1;
	}
}
