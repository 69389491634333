@import (reference) "~@tehzor/ui-components/src/styles/colors";

.wrapper {
  padding: 8px;
  position: relative;
  margin: 0 12px 12px 0;
}

.wrapperActive {
  background-color: @blue30;
}

.icon {
  width: 24px;
  height: 24px;
  color: @blue2;
}

.indicator {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: @red60;
}

.label {
  font-size: 14px;
  font-weight: 600;
  color: @black85
}