@import (reference) "~@tehzor/ui-components/src/styles/colors";

.layout {
  width: 376px;
  max-width: none;
  z-index: 1;
}

.wrapper {
  padding: 0 16px 16px 24px;
}

.header {
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid @gray8;
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: @black4;
}

.trigger {
  max-width: 213px;
  padding: 8px;
  position: relative;
  margin: 0 12px 12px 0;
}

.triggerIcon {
  width: 24px;
  height: 24px;
  color: @blue2;
}

.indicator {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: @red60;
}

.triggerLabel {
  font-size: 14px;
  font-weight: 600;
  color: @black85;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}