@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";
@import (reference) "../../styles/typography";

@tree: tree;

.@{tree} {
	margin: 0;
	padding: 0;

	&__item {
		margin-top: 4px;
		padding: 0;
		outline: none;
		user-select: none;

		&:first-child {
			//margin-top: 0;
		}

		&_level {
			&_0 {
				margin-top: 10px;
			}

			&_1 {
				margin-top: 8px;
			}

			&_2 {
				margin-top: 6px;
			}
		}

		li& {
			list-style: none;
		}

		&-row {
			display: flex;
			align-items: center;
			min-height: 28px;

			&_padding {
				padding-left: 44px;
			}
		}

		&-expand-btn {
			.icon-button& {
				margin-right: 4px;
				color: @blue2;
			}

			&-icon {
				transform: rotate(0deg);
				transition: transform 0.2s cubic-bezier(0.69, 0.17, 0.56, 0.77);

				&_expanded {
					transform: rotate(-180deg);
				}
			}
		}

		&-checkbox {
			margin-right: 13px;
		}

		&-label {
			.d-regular-14();
			color: rgba(@black4, 0.7);
			transition: color 0.2s ease;

			&_level {
				&_0 {
					font-size: 17px;
					font-weight: 600;
					color: rgba(@black4, 0.85);
				}

				&_1 {
					font-size: 15px;
					font-weight: 600;
					color: rgba(@black4, 0.85);
				}

				&_2 {
					font-weight: 600;
				}
			}

			.desktop .switch:not(.switch_disabled):hover & {
				color: @blue2;
			}

			.switch:not(.switch_disabled):active & {
				color: darken(@blue2, 10%) !important;
			}
		}

		&-children-wrap {
			overflow: hidden;
		}

		&-children {
			width: 100%;
			padding-top: 4px;
			padding-bottom: 6px;
			padding-left: 30px;

			&_level {
				&_0 {
					padding-bottom: 12px;
				}

				&_1 {
					padding-bottom: 10px;
				}

				&_2 {
					padding-bottom: 8px;
				}
			}
		}
	}
}

.virtual-tree {
	padding-top: 10px;

	&__item {
		padding: 0;
		outline: none;
		user-select: none;

		display: flex;
		flex-direction: column;
		height: auto;

		&-inner {
			display: flex;
			align-items: center;
			height: 28px;

			&_level {
				&_1 {
					margin-top: 10px;
					padding-left: 80px;
				}

				&_2 {
					margin-top: 8px;
					padding-left: 120px;
				}

				&_3 {
					margin-top: 6px;
					padding-left: 160px;
				}
			}

			&_children {
				margin-top: 4px;
			}

			&_children_level {
				&_0 {
					margin-bottom: 12px;
				}

				&_1 {
					padding-left: 36px;
				}

				&_2 {
					padding-left: 54px;
				}

				&_3 {
					padding-left: 72px;
				}
			}
		}

		&-expand-btn {
			.icon-button& {
				margin-right: 4px;
				color: @blue2;
			}

			&-icon {
				transform: rotate(0deg);
				transition: transform 0.2s cubic-bezier(0.69, 0.17, 0.56, 0.77);

				&_expanded {
					transform: rotate(-180deg);
				}
			}
		}

		&-checkbox {
			margin-right: 13px;
		}

		&-label {
			.d-regular-14();
			color: rgba(@black4, 0.7);
			transition: color 0.2s ease;

			&_level {
				&_0 {
					font-size: 17px;
					font-weight: 600;
					color: rgba(@black4, 0.85);
				}

				&_1 {
					font-size: 15px;
					font-weight: 600;
					color: rgba(@black4, 0.85);
				}

				&_2 {
					font-weight: 600;
				}
			}

			.desktop .switch:not(.switch_disabled):hover & {
				color: @blue2;
			}

			.switch:not(.switch_disabled):active & {
				color: darken(@blue2, 10%) !important;
			}
		}
	}
}