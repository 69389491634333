@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.document-page {
	@media (max-width: (@tablet-min - 1px)) {
		padding: 16px 0 0;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 16px;
		}
	}

	&__title {
		font-weight: 600;
		padding-bottom: 20px;
		&--dialog {
			font-weight: 700;
			font-size: 24px;
			line-height: 34px;
		}
		&--dialog-span {
			font-weight: 700;
			font-size: 24px;
			line-height: 34px;
			color: #969fa9;
		}
	}

	&__entity-paragraph {
		font-weight: 600;
		font-size: 14px;
		line-height: 19.6px;
		margin: 0;
		&--objects {
			font-weight: 400;
			padding: 0;
			margin: 0;
			gap: 4px;
		}
	}

	&__objects-list {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
	&__plates {
		@media (min-width: @tablet-min) {
			display: flex;
			grid-column-gap: 32px;
			grid-template-columns: 368px auto;
		}
		&--info {
			display: flex;
			flex-direction: column;
			gap: 12px !important;
		}
	}

	&__info-plate {
		min-width: 400px;
		padding: 17px 24px 24px 24px;
	}

	&__links-plate {
		width: 400px;
		padding: 20px 24px 20px 24px;
	}
	&__links-dialog {
		min-width: 512px;
	}
	&__links {
		display: flex;
		flex-direction: column;
		padding: 0;
		gap: 16px;
		overflow-x: hidden;

		&--dialog {
			display: flex;
			flex-direction: column;
			gap: 16px;
			margin-top: 32px;
			padding: 0 0 20px 0;
			width: 90%;
		}
	}
	&__link {
		display: flex;
		flex-direction: row;
		overflow-x: hidden;
		width: fit-content;
		text-overflow: ellipsis;
		padding: 5px;
	}
	&__preview-plate {
		width: 100%;
	}

	&__preview-plate-content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;
	}

	&__preview-message {
		margin-top: 36px;
		color: @black35;
		.d-semibold-14();
	}
	&__objects-more-btn {
		padding: 1px 6px;
		background-color: #eef5fb;
	}
}
