@import (reference) "~@tehzor/ui-components/src/styles/colors.less";

.title {
	margin: 0;
	padding: 16px 0;
	font-weight: 400;
	font-size: 16px;
	color: @black85;
}

.fieldContainer {
	display: grid;
	grid-template-columns: 1fr max-content;
	grid-template-rows: 1fr;
	grid-gap: 0 16px;
	align-items: center;
}