@import (reference) '../../../styles/variables';

.entity-select-container {
	&__entity-btn-container {
		margin-bottom: 8px;
		width: fit-content;
		max-width: 452px;

		@media (max-width: @tablet-min) {
			width: 100%;
		}
	}

	&__entity-btn {
		@media (max-width: @tablet-max) {
			margin: 8px 0px 15px 0px;
		}

		@media (max-width: @tablet-min - 1px) {
			max-width: 100%;
		}
	}
}
