@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.icon-button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-width: 40px;
	min-height: 40px;
	padding: 5px;
	background-color: transparent;
	border: none;
	border-radius: 8px;
	transition: background-color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease, color 0.2s ease;

	button&_common {
		background-color: @button;
		color: @buttonAccent;

		.desktop &:hover {
			background-color: @buttonHover;
		}

		.desktop &:active, .mobile &:active {
			background-color: @buttonPressed;
		}
	}

	&_active&_common {
		background-color: @buttonHover;
	}

	button&_accent-blue {
		background-color: @buttonAccent;
		color: @white;

		.desktop &:hover {
			background-color: @buttonAccentHover;
		}

		.desktop &:active, .mobile &:active {
			background-color: @buttonAccentPressed;
		}
	}

	&_active&_accent-blue {
		background-color: @buttonAccentHover;
	}

	button&_transparent {
		color: @buttonAccent;

		.desktop &:hover {
			background-color: @buttonHover;
		}

		.desktop &:active, .mobile &:active {
			background-color: @buttonPressed;
		}
	}

	&_active&_transparent {
		background-color: @buttonHover;
	}

	button&_inline {
		color: @grey;

		.desktop &:hover {
			color: @greyLight;
		}

		.desktop &:active, .mobile &:active {
			color: @greyHover;
		}
	}

	&_active&_inline {
		color: @greyLight;
	}

	button&_inline-blue {
		color: @grey;

		.desktop &:hover {
			color: @buttonAccentHover;
		}

		.desktop &:active, .mobile &:active {
			color: @buttonAccentPressed;
		}
	}

	&_active&_inline-blue {
		color: @buttonAccentHover;
	}

	button&_inline-blue-accent {
		color: @buttonAccent;

		.desktop &:hover {
			color: @buttonAccentHover;
		}

		.desktop &:active, .mobile &:active {
			color: @buttonAccentPressed;
		}
	}

	&_active&_inline-blue-accent {
		color: @buttonAccentHover;
	}

	button&_inline-red {
		color: @grey;

		.desktop &:hover {
			color: @buttonRedHover;
		}

		.desktop &:active, .mobile &:active {
			color: @buttonRedPressed;
		}
	}

	&_active&_inline-red {
		color: @buttonRedHover;
	}

	button&_inline-red-accent {
		color: @buttonRed;

		.desktop &:hover {
			color: @buttonRedHover;
		}

		.desktop &:active, .mobile &:active {
			color: @buttonRedPressed;
		}
	}

	&_active&_inline-red-accent {
		color: @buttonRedHover;
	}

	button&_inline-green {
		background-color: @green10;
		color: @statusFixed;

		.desktop &:hover {
			color: @statusFixed;
		}

		.desktop &:active, .mobile &:active {
			color: @green10;
		}
	}

	&_disabled {
		color: rgba(@grayBlue4, 0.4);
	}

	&_inline-green-disabled {
		background-color: @grey10;
	}

	&_common-disabled {
		border: 1px solid rgba(@grayBlue4, 0.05);

		button& {
			border: 1px solid rgba(@grayBlue4, 0.05);
		}
	}

	&_accent-disabled {
		background-color: rgba(@black4, 0.05);

		button& {
			background-color: rgba(@black4, 0.05);
		}
	}

	&_cancel-disabled {
		border: 1px solid rgba(@grayBlue4, 0.05);

		button& {
			border: 1px solid rgba(@grayBlue4, 0.05);
		}
	}

	&__icon {
		line-height: 1em;

		i {
			display: block;

			&::before {
				display: block;
			}
		}
	}
}
