.table-upload-page {
	display: grid;
	grid-column-gap: 24px;
	align-items: flex-start;
	grid-template-columns: 4fr 2fr 1fr 300px;

	&__result {
		margin: 18px;
	}

	&__button {
		margin: 18px;
	}
}