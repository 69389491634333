@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.spaces-page {
	@media (max-width: @tablet-min - 1px) {
		padding: 16px 0 0;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}

		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 16px;
		}
	}

	&__d-header-tabs,
	&__d-controls-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__d-header-tabs {
		margin-bottom: 24px;
	}

	&__d-controls-bar {
		margin-bottom: 16px;
	}

	&__m-controls-bar {
		display: grid;
		grid-template-columns: min-content 1fr min-content min-content;
		grid-template-rows: auto auto;
		align-items: center;
		margin-bottom: 12px;

		@media (max-width: @tablet-min - 1px) {
			padding: 0 8px;
		}
	}

	&__mobile-cell {
		padding: 0;
	}

	&__m-display-mode-switch {
		z-index: 1191; // TODO Удалить после переработки навигации (боковое меню перекрывает кнопку)
	}

	&__plate {
		&-header {
			border-bottom: 1px solid @black10;
			background-color: @white3;
			min-height: 56px;
			display: flex;
			align-items: center;

			&-tabs {
				flex: 1 1;
				align-self: end;

				@media (min-width: @desktop-min) {
					margin-right: 20px;
					border-bottom: 1px solid #D9DDE3;
				}
			}

			&-actions {
				margin-left: auto;
				margin-right: 12px;
			}
		}

		&-content {
			padding: 0 24px 24px;
		}
	}

	&__m-pagination {
		border-bottom: 1px solid @black10;
	}

	&__m-legend-button {
		margin: 0 8px 0 0;

		button& {
			color: @greyLight;
		}
	}

	&__legend {
		&-popup {
			&-content {
				width: 568px;
				height: 632px;
				overflow-y: auto;
				padding-right: 16px;
			}
		}

		&-dialog {
			@media (min-width: @large-mobile-min) {
				width: calc(100vw - 32px);
			}

			@media (min-width: @tablet-min) {
				width: 650px;
			}
		}

		&-items-group {
			&-space-status {
				grid-row: 2 / span 1;

				@media (min-width: @large-mobile-min) {
					grid-row: none;
				}
			}
		}

		&-content {
			display: grid;
			grid-template-columns: 1fr;
			grid-column-gap: 40px;
			grid-row-gap: 14px;
			grid-auto-rows: minmax(100px, auto);
			@media (min-width: @large-mobile-min) {
				grid-template-columns: repeat(2, 1fr);
			}
		}

		&-items-group {
			&:nth-child(1) {
				gap: 12px;
				grid-row: span 1;
			}
			&:nth-child(2) {
				gap: 16px;
				grid-row: span 4;
			}
			&:nth-child(3) {
				gap: 16px;
			}
		}

		&-indicator {
			border-radius: 50%;
			margin: auto;
			width: 6px;
			height: 6px;
		}

		&-problem-status {
			border-radius: 50%;
			border: 2px solid red;
			height: 14px;
			width: 14px;
		}

		&-work-acceptance-status {
			border-radius: 50%;
			border: 2px solid;
			height: 16px;
			width: 16px;
		}

		&-space-status {
			border-radius: 50%;
			width: 16px;
			height: 16px;

			&-title {
				.d-regular-12();
				color: @black75;
				margin-top: 20px;
				margin-bottom: 10px;
			}
		}

		&-check-item-status {
			border-radius: 50%;
			height: 20px;
			width: 20px;
		}

		&-check-list-status {
			border-radius: 50%;
			border: 2px solid red;
			width: 100%;
		}

		&-item {
			display: flex;
			margin-bottom: 10px;

			&:first-child {
				margin-bottom: 16px;
			}

			&-header {
				.d-semibold-14();
				color: @black4;
			}

			&-title {
				display: flex;
				align-items: center;
				.d-regular-12();
			}

			&-icon {
				display: flex;
				align-items: center;
				margin-left: 6px;
				margin-right: 10px;

				> i {
					display: flex;
					justify-content: center;
					width: 14px;
					height: 16px;
					color: @red70;
					font-size: 18px;
				}

				& > div {
					box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
				}
			}
		}
	}

	&__loading-panel {
		min-height: 100%;
	}

	&__settings-popup {
		width: 320px;

		&-title {
			margin-bottom: 16px;
			padding: 5px 0 12px;
			border-bottom: 1px solid @black10;
			font-weight: 600;
		}
	}

	&__settings-dialog {
		@media (min-width: @tablet-min) {
			min-width: 460px;
		}
	}

	&__setting {
		display: block;

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&__settings-btn {
		margin-right: 16px;
		margin-left: auto;
		//box-shadow: @shadow2;
	}

	&__space-type-list {
		display: flex;
		flex-wrap: wrap;
		margin: 16px -6px;

		&-item {
			margin: 6px;
			flex: 0 0 auto;
		}
	}

	&__space-type-menu {
		&-btn-wrap {
			display: flex;
			justify-content: center;
			padding: 0 8px 0 56px;
			overflow: hidden;
		}

		&-btn {
			max-width: 100%;
			color: @black85;

			&-label {
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 20px;
			}

			&-icon {
				margin-left: 8px;
				color: @greyLight;
				transition: transform 0.2s ease;

				&_open {
					transform: rotate(180deg);
				}
			}
		}

		&-dialog {
			@media (min-width: @large-mobile-min) {
				min-width: 320px;
			}
		}
	}

	&__schema-list {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 80px;
		min-height: 250px;
		margin: 0 -24px;

		@media (min-width: @tablet-min) and (max-width: @desktop-min - 1px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (min-width: @desktop-min) and (max-width: @large-desktop-min - 1px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media (min-width: @large-desktop-min) {
			grid-template-columns: repeat(4, 1fr);
		}

		&_single-col {
			grid-template-columns: 1fr;
			grid-row-gap: 0;
		}

		&-item {
			padding: 8px 16px 16px;

			&_subtitle {
				color: #AEB5BD;
			}

			@media (min-width: @tablet-min) {
				padding: 0 24px;
			}

			@media (min-width: @large-desktop-min) {
				&:nth-child(4n + 2),
				&:nth-child(4n + 3),
				&:nth-child(4n + 4) {
					border-left: 1px solid @black10;
				}
			}

			@media (min-width: @desktop-min + 12px) and (max-width: @large-desktop-min - 1px) {
				&:nth-child(3n + 2),
				&:nth-child(3n + 3) {
					border-left: 1px solid @black10;
				}
			}

			@media (min-width: @tablet-min) and (max-width: @desktop-min + 11px) {
				&:nth-child(even) {
					border-left: 1px solid @black10;
				}
			}
		}
	}

	&__reports-table {
		border-spacing: 0;
		min-width: inherit;
		position: relative;
		border-collapse: collapse;

		&-plate {
			overflow-x: scroll;

			@media (max-width: @tablet-max) {
				padding-left: 24px;
			}
		}

		&-field-buttons {
			margin: 24px 24px 40px 24px;
			display: flex;
			gap: 12px;
		}

		&-header,
		&-body {
			&__row-cell {
				.d-regular-14();
				border-top: 0.5px solid @grey20;
				border-bottom: 0.5px solid @grey20;
				border-right: 0.5px solid @grey20;
				color: @black100;
				min-width: 130px;

				&:nth-child(1) {
					color: @blue2;
					border-right: none;
					min-width: 25px;
					width: 25px;
				}

				&:nth-child(2) {
					text-align: start;
					min-width: 344px;
				}
			}
		}

		&-header {
			&__row {
				&-cell {
					padding: 10px 16px;
					text-align: center;
					.d-regular-12();

					&:nth-child(1) {
						padding: 0;
					}

					&:nth-child(2) {
						.d-regular-14();
						padding: 0;
						color: @black60;
					}
				}
			}
		}

		&-body {
			&__row-cell {
				.name-cell-type-header {
					padding: 35px 0 12px 0;
					.d-semibold-16();
				}

				&:nth-child(1) {
					position: relative;
					top: 12px;
					padding-left: 10px;
					transition: transform 0.2s;
					transform: rotate(-90deg);

					&:has(.isExpanded) {
						transform: rotate(0deg);
					}
				}

				& > div {
					display: flex;
					justify-content: center;
					align-items: center;
					min-height: 45px;
				}

				&:nth-child(2) > div {
					justify-content: flex-start;
				}
			}
		}
	}

	&__divider {
		margin: 0;
	}

	&__filter-bar {
		display: flex;
		align-items: center;
		flex-direction: row;
		margin: 0 0 16px 16px;
	}
	&__toolbar {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
	&__toolbar-count {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 4px;
	}
	&__toolbar-btns {
		display: flex;
		flex-direction: row;
		gap: 12px;

		&--hover-popup-root {
			padding: 5px;
			background-color: @grey100;
			opacity: 0.9;
			color: @white;
			path {
				opacity: 0.9;
				fill: @grey100;
			}
		}
		&--hover-popup-content {
			p {
				margin: 0 10px;
			}
		}
	}

	&__table-selection-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
	}

	&__toolbar-mobile {
		position: fixed;
		z-index: 1000;
		bottom: 10px;
		right: 20px;

		color: @black4;
		background: @white;
		width: 100%;
		height: 56px;
		display: flex;
		padding: 8px;
		gap: 8px;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		&-left-side {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 10px;

			&-close {
				display: flex;
				width: 40px;
				height: 40px;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;
			}
		}

		&-right-side {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 10px;

			&-checkbox {
				display: flex;
				width: 40px;
				height: 40px;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;
			}
		}

		&-opendialog-btn {
			position: fixed;
			z-index: 1000;
			bottom: 14px;
			right: 14px;
			border-radius: 55px;
		}
	}
}
