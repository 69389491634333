@import (reference) "~@tehzor/ui-components/src/styles/colors";

.wrapper {
	display: grid;
	grid-template-columns: min-content 1fr min-content;
	grid-template-rows: 1fr;
	grid-gap: 0 8px;
	align-items: center;
}

.title {
	margin: 0;
	font-weight: 400;
	font-size: 14px;
	color: @black85;
}

.bar {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0 8px;
}

.button {
	width: 24px;
	height: 24px;
	min-width: auto;
	min-height: auto;
}

.buttonIcon {
	font-size: 17px;
}