@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.manage-task-schedule-page {
	&-bar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
	}

	&__tabs {
		border-bottom: 1px solid @gray8;
		width: 100%;

		&-fake-link::after {
			height: 3px;
			border-radius: 0;
		}
	}

	&__legend {
		&-content {
			display: flex;
			flex-direction: column;
			gap: 26px;

			&-title {
				.d-semibold-14();

				width: 150px;
				margin-bottom: 12px;
			}

			&-items {
				display: flex;
				flex-direction: column-reverse;
			}

			&-item {
				display: flex;
				align-items: center;
				margin-bottom: 12px;
				margin-left: 8px;

				&-status {
					display: inline-block;
					flex: none;

					width: 20px;
					height: 20px;
					margin-right: 8px;

					border-radius: 50%;

					&-title {
						margin-left: 4px;
					}
				}

				&-type {
					display: inline-block;
					flex: none;

					width: 4px;
					height: 20px;
					margin-right: 8px;
					margin-left: 8px;

					&-title {
						margin-left: 12px;
					}
				}
			}
		}
	}

	&__object-cell {
		cursor: default;
		user-select: none;

		display: flex;
		column-gap: 4px;

		height: 80px;
		padding-top: 14px;

		color: @black100;

		&-pointer {
			cursor: pointer;
		}

		&-title {
			display: flex;
			flex-direction: column;
			row-gap: 4px;
			justify-content: center;

			&_active {
				justify-content: flex-start;
			}

			&-name {
				.d-regular-14();
			}

			&-name-bold {
				.d-semibold-14();
			}
		}

		&-expander {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 26px;

			color: @blue2;

			border: 50%;

			transition: all 0.2s ease-in;
		}

		&-objects {
			.d-regular-12();

			color: @black75;
		}
	}

	&__tasks-unassined-cell {
		display: flex;

		width: 104px;
		min-height: 80px;
		padding: 0;

		background-color: inherit;
		border: none;
		outline: none;

		&-tasks {
			display: flex;
			flex-wrap: wrap;
			gap: 4px;
			align-content: flex-start;

			height: 80px;
			padding: 2px;
		}
	}

	&__header {
		display: flex;
		justify-content: center;
		height: 48px;
		border-bottom: 1px solid @gray8;

		&-year {
			display: flex;
			align-items: center;
			justify-content: center;

			height: 48px;
			padding: 5px;

			font-size: 1rem;

			&_title {
				.d-semibold-16();

				color: @black85;
			}

			&_arrow-left {
				cursor: pointer;
				transform: rotate(90deg);
				margin-right: 20px;
				color: @blue2;
			}

			&_arrow-right {
				cursor: pointer;
				transform: rotate(-90deg);
				margin-left: 20px;
				color: @blue2;
			}
		}
	}

	&__grouped-tasks {
		&_item {
			cursor: pointer;

			display: flex;
			align-items: center;
			justify-content: center;

			box-sizing: border-box;
			width: 24px;
			height: 24px;
			padding-right: 4px;

			font-family: 'Open Sans', sans-serif;
			color: @black100;

			border-radius: 3px;
			.d-regular-12();

			&:hover {
				filter: contrast(105%);
			}

			&:active {
				filter: contrast(110%);
			}
		}

		&_item-task {
			cursor: pointer;

			display: flex;

			width: 100%;
			padding: 2px 26px 2px 8px;

			font-family: 'Open Sans', sans-serif;
			color: @deepBlue;

			border-radius: 2px;
			.d-semibold-12();

			&:hover {
				background-color: @white2;
			}

			&:active {
				background-color: @buttonPressed;
			}
		}
	}

	&__month-header {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 123px;
		height: 32px;

		border-right: 1px solid @gray8;

		&_active {
			color: @deepBlue;
			background-color: @black05;
		}
	}

	&__month-cell-wrap {
		display: flex;
		flex-direction: column;
		row-gap: 2px;

		width: 120px;
		height: 80px;
		min-height: 80px;
		padding: 2px;

		&_active {
			background-color: @black05;
		}
	}

	&__month-cell {
		display: flex;
		flex-wrap: wrap;

		&-tasks {
			display: flex;
			flex-wrap: wrap;
			gap: 4px;
			height: min-content;
		}

		&_pop-up {
			position: absolute;
			z-index: 9999;
			top: 10px;
			left: 5px;

			overflow: visible;

			background-color: white;
			clip-path: none;
			border: 1px solid black;
		}
	}

	&__month-cell-add {
		cursor: pointer;

		display: flex;
		flex-grow: 1;
		align-items: center;
		justify-content: center;

		width: 100%;

		font-size: 48px;
		color: transparent;
		text-align: center;

		border-radius: 3px;

		&:hover {
			background-color: @white2;
		}

		&:active {
			background-color: @buttonPressed;
		}

		&:hover {
			color: #3391ff;
		}
	}

	&__day-header-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 123px;
		height: 32px;

		border-right: 1px solid @gray8;

		&_active {
			color: @deepBlue;
			background-color: @black05;
		}
	}

	&__day-header {
		display: flex;
		gap: 8px;
		align-items: baseline;
		justify-content: center;

		&-name {
			.d-semibold-12();

			color: @black35;

			&_active {
				color: @deepBlue;
				background-color: @black05;
			}
		}
	}

	&__day-cell {
		position: relative;

		display: flex;
		flex-direction: column;
		row-gap: 2px;

		width: 120px;
		min-height: 80px;
		padding: 2px;

		&-tasks {
			position: relative;

			&-item {
				cursor: pointer;

				position: absolute;
				z-index: 100;

				overflow: hidden;
				display: flex;
				align-items: center;

				height: 24px;
				padding-left: 4px;

				white-space: nowrap;

				border-radius: 3px;
				.d-regular-12();

				&:hover {
					filter: contrast(105%);
				}

				&:active {
					filter: contrast(110%);
				}

				&-hidden {
					cursor: pointer;

					position: absolute;
					top: 112px;
					z-index: 1;

					display: flex;

					width: 100%;
					padding: 2px 26px 2px 8px;

					font-family: 'Open Sans', sans-serif;
					color: @deepBlue;

					border-radius: 2px;
					.d-semibold-12();

					&:hover {
						background-color: @white2;
					}

					&:active {
						background-color: @buttonPressed;
					}
				}
			}
		}

		&_active {
			background-color: @black05;
		}
	}

	&__day-cell-add {
		cursor: pointer;

		position: absolute;
		bottom: 0;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 97%;
		height: 28px;

		font-size: 48px;
		color: transparent;
		text-align: center;

		border-radius: 3px;

		&:hover {
			color: @buttonAccent!important;
			background-color: @white2;
		}

		&:active {
			background-color: @buttonPressed;
		}
	}

	&__hover-layout {
		position: absolute;
		inset: 0;
		z-index: 0;
		background-color: transparent;
	}
}

// срабатывает при наведении курсора на кнопку добавления и на задачу
.manage-task-schedule-page__day-cell:hover .manage-task-schedule-page__day-cell-add {
	color: transparent;
}

// срабатывает при наведении курсора на ячейку, за пределами кнопки добавления и задач
.manage-task-schedule-page__day-cell-tasks-item:hover ~ .manage-task-schedule-page__day-cell-add,
.manage-task-schedule-page__hover-layout:hover ~ .manage-task-schedule-page__day-cell-add {
	color: @buttonAccent;
	background-color: @white2;
}
