@icomoon-font-family: "tz-icons";
@icomoon-font-path: "fonts";

@tz-structure-types-20: '\ea07';
@tz-plan-20: '\ea08';
@tz-search: '\ea06';
@tz-settings: '\ea05';
@tz-choose-20: '\ea04';
@tz-user-owner-20: '\ea03';
@tz-export-template-20: '\ea01';
@tz-added-20: '\ea02';
@tz-toast-warning-32: '\e9fc';
@tz-toast-success-32: '\ea00';
@tz-toast-info-32: '\e9fe';
@tz-toast-error-32: '\e9ff';
@tz-presale-control-process-16: '\e9fb';
@tz-choice-circle-24: '\e9fa';
@tz-operational-control-process-16: '\e9f5';
@tz-warranty-service-process-16: '\e9f6';
@tz-units-handover-process-16: '\e9f7';
@tz-internal-acceptance-process-16: '\e9f8';
@tz-acceptance-control-process-16: '\e9f9';
@tz-repeat-16: '\e9f4';
@tz-fields-28: '\e9f3';
@tz-logo-latin: '\e9f1';
@tz-logo-cyrillic: '\e9f2';
@tz-empty-table: '\e9f0';
@tz-attach-20: '\e9ea';
@tz-links-list: '\e9dd';
@tz-custom-rhombus-20: '\e9de';
@tz-custom-socket-20: '\e9df';
@tz-custom-lamp-20: '\e9e0';
@tz-custom-shovel-20: '\e9e1';
@tz-custom-exclamation-point-20: '\e9e2';
@tz-custom-rectangles-20: '\e9e3';
@tz-custom-block-20: '\e9e4';
@tz-custom-snowflake-20: '\e9e5';
@tz-custom-shield-20: '\e9e6';
@tz-custom-triangle-20: '\e9e7';
@tz-custom-hexagon-20: '\e9e8';
@tz-custom-polygon-20: '\e9e9';
@tz-Icon1: '\e9dc';
@tz-inspection-add-24: '\e9d9';
@tz-problem-add-24: '\e9da';
@tz-task-add-24: '\e9db';
@tz-check-list-categories-20: '\e9d8';
@tz-space-16: '\e9d7';
@tz-tile-24: '\e9d6';
@tz-attach-16: '\e9d5';
@tz-document-16: '\e9d4';
@tz-problem-24: '\e9d2';
@tz-link-20: '\e9d3';
@tz-bank-account-28: '\e9d1';
@tz-classifier-20: '\e9ce';
@tz-address-20: '\e9d0';
@tz-phone-20: '\e9cf';
@tz-legal-entity-28: '\e9c9';
@tz-legal-entity-20: '\e9c1';
@tz-modified-20: '\e9c2';
@tz-number-20: '\e9c4';
@tz-price-20: '\e9c6';
@tz-stage-20: '\e9c7';
@tz-bank-account-20: '\e9c8';
@tz-contract-28: '\e9ca';
@tz-contractor-20: '\e9cb';
@tz-created-20: '\e9cc';
@tz-file-type-pdf: '\e9be';
@tz-file-type-doc: '\e9cd';
@tz-file-type-xls: '\e9c0';
@tz-inspector-20: '\e9bd';
@tz-task-16: '\e9ba';
@tz-comment-20: '\e9b9';
@tz-problem-20: '\e9b8';
@tz-percent-20: '\e9b6';
@tz-unit-20: '\e9b7';
@tz-task-type-20: '\e9b5';
@tz-star-priority-20: '\e9b2';
@tz-outline-object-20: '\e9b1';
@tz-structure: '\e9af';
@tz-inspection-16: '\e9ab';
@tz-company-20: '\e9a9';
@tz-check-24: '\e9a7';
@tz-owner-acceptance-24: '\e9a3';
@tz-task-28: '\e92e';
@tz-check-28: '\e918';
@tz-owner-acceptance-28: '\e92d';
@tz-drag-and-drop-24: '\e9a1';
@tz-task-24: '\e9a2';
@tz-warning-20: '\e99f';
@tz-category-20: '\e994';
@tz-priority-highest-16: '\e99b';
@tz-priority-high-16: '\e995';
@tz-priority-medium-16: '\e996';
@tz-notifications-default-entity: '\e948';
@tz-notifications-default-type: '\e993';
@tz-notifications-attachment: '\e992';
@tz-notifications-message: '\e991';
@tz-notifications-inspection: '\e990';
@tz-notifications-status: '\e98d';
@tz-notifications-add: '\e98a';
@tz-notifications-edit: '\e988';
@tz-notifications-delete: '\e986';
@tz-prescription-20: '\e982';
@tz-eraser-24: '\e983';
@tz-brush-24: '\e984';
@tz-brush-20: '\e985';
@tz-space-type-20: '\e980';
@tz-attachment: '\e97e';
@tz-chat: '\e987';
@tz-email: '\e989';
@tz-reason-external-link: '\e98b';
@tz-find: '\e98e';
@tz-fix-date: '\e98f';
@tz-toast-info: '\e9a4';
@tz-toast-success: '\e9a5';
@tz-toast-warning: '\e9a6';
@tz-problem-hexagon: '\e971';
@tz-few-sample: '\e910';
@tz-not-accepted: '\e96f';
@tz-repeat-sample: '\e970';
@tz-check-mark: '\e90f';
@tz-percent: '\e906';
@tz-logo: '\e904';
@tz-cloud: '\e929';
@tz-cloud-caching-active: '\e92a';
@tz-cloud-caching-done: '\e92b';
@tz-cloud-caching-error: '\e92c';
@tz-cloud-sync: '\e932';
@tz-arrow-16: '\e97a';
@tz-arrow-20: '\e974';
@tz-long-arrow-24: '\e928';
@tz-arrow-24: '\e954';
@tz-long-arrow: '\e902';
@tz-simple-arrow-16: '\e927';
@tz-simple-arrow-20: '\e95f';
@tz-simple-arrow-24: '\e920';
@tz-simple-arrow-28: '\e96b';
@tz-expand-arrow-heavy: '\e913';
@tz-corner-arrow: '\e921';
@tz-reload-20: '\e975';
@tz-reload-24: '\e945';
@tz-status-20: '\e946';
@tz-calendar-20: '\e947';
@tz-calendar-24: '\e916';
@tz-calendar-done-20: '\e9b4';
@tz-location-20: '\e949';
@tz-indicator-20: '\e964';
@tz-area-20: '\e965';
@tz-space-owner-20: '\e966';
@tz-description-20: '\e94a';
@tz-external-link-16: '\e937';
@tz-external-link-20: '\e94b';
@tz-photo-20: '\e950';
@tz-document-20: '\e94c';
@tz-edit-16: '\e967';
@tz-edit-20: '\e94d';
@tz-clock: '\e935';
@tz-alarm-clock: '\e936';
@tz-plan: '\e905';
@tz-map: '\e907';
@tz-menu: '\e90a';
@tz-more-16: '\e977';
@tz-more-24: '\e931';
@tz-edit: '\e908';
@tz-delete: '\e909';
@tz-copy: '\e90d';
@tz-user-20: '\e94e';
@tz-user-24: '\e933';
@tz-users-24: '\e972';
@tz-add-user-24: '\e978';
@tz-camera-24: '\e94f';
@tz-exit: '\e934';
@tz-comment: '\e93f';
@tz-comment-88: '\e951';
@tz-plus-16: '\e969';
@tz-plus-20: '\e952';
@tz-plus-24: '\e940';
@tz-minus: '\e941';
@tz-attach-24: '\e942';
@tz-send: '\e943';
@tz-mark-16: '\e90b';
@tz-mark-2-16: '\e979';
@tz-mark-24: '\e90c';
@tz-close-16: '\e924';
@tz-close-20: '\e903';
@tz-close-24: '\e925';
@tz-table-sort-20: '\e911';
@tz-filters-24: '\e914';
@tz-export-20: '\e953';
@tz-export-24: '\e915';
@tz-bar-chart: '\e922';
@tz-pie-chart: '\e923';
@tz-line-chart: '\e926';
@tz-company-28: '\e957';
@tz-object-28: '\e958';
@tz-internal-acceptance-28: '\e962';
@tz-internal-acceptance-24: '\e939';
@tz-problem-16: '\e90e';
@tz-problem-28: '\e968';
@tz-problem: '\e919';
@tz-problem-waiting: '\e917';
@tz-problem-handling: '\e91b';
@tz-problem-verification: '\e91c';
@tz-problem-fixed: '\e91d';
@tz-problem-expired: '\e91e';
@tz-problem-created: '\e91f';
@tz-comment-40: '\e955';
@tz-inspection: '\e91a';
@tz-inspection-28: '\e96a';
@tz-space: '\e938';
@tz-space-24: '\e93a';
@tz-space-40: '\e956';
@tz-space-status-28: '\e95a';
@tz-warranty-claim-24: '\e93b';
@tz-warranty-claim-28: '\e963';
@tz-statistics-28: '\e9aa';
@tz-user-28: '\e95b';
@tz-role-28: '\e95d';
@tz-export-template-28: '\e95e';
@tz-reg-standard-28: '\e960';
@tz-log-28: '\e961';
@tz-help: '\e930';
@tz-docx: '\e93c';
@tz-xlsx: '\e93d';
@tz-pdf: '\e93e';
@tz-file: '\e912';
@tz-circle-warning-32: '\e944';
@tz-search-20: '\e96c';
@tz-info-20: '\e96d';
@tz-info-24: '\e96e';
@tz-settings-24: '\e973';
@tz-name-20: '\e976';
@tz-list-24: '\e901';
@tz-schema-24: '\e92f';
@tz-delegate-20: '\e97b';
@tz-delegate-24: '\e9a8';
@tz-critical-20: '\e9ae';
@tz-history-24: '\e97c';
@tz-mark-20: '\e997';
@tz-offline-mode-20: '\e99a';
@tz-data-transfer-error-20: '\e998';
@tz-data-transfer-20: '\e999';
@tz-password-visible-20: '\e99c';
@tz-password-hidden-20: '\e99d';
@tz-mail-20: '\e99e';
@tz-tag-20: '\e9a0';
@tz-object-24: '\e9ac';
@tz-comment-on-plan-16: '\e9b3';
@tz-work-acceptance-28: '\e9ad';
@tz-work-acceptance-16: '\e9bc';
@tz-export-template-manage-28: '\e900';
@tz-tag-manage-28: '\e959';
@tz-category-manage-28: '\e95c';
@tz-space-type-manage-28: '\e97d';
@tz-group-manage-28: '\e97f';
@tz-check-list-16: '\e9bb';
@tz-check-list-manage-28: '\e981';
@tz-space-status-manage-28: '\e98c';
@tz-eye-20: '\e9b0';
@tz-document-type-20: '\e9bf';
@tz-document-format-20: '\e9c3';
@tz-customer-20: '\e9c5';
@tz-heat-20: '\e9eb';
@tz-gas-20: '\e9ec';
@tz-electricity-20: '\e9ed';
@tz-water-cold-20: '\e9ee';
@tz-water-hot-20: '\e9ef';
@tz-presale-check-28: '\e9fd';
