@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';


.work-acceptance-acceptors__container {
    @media (max-width: @desktop-min) {
        font-size: 12px;
    }

    >*:not(:first-child) {
        margin-left: 8px;
    }


}

.work-acceptance-acceptors__label {
    color: @black60;
}

.work-acceptance-acceptors__company {
    margin-left: 8px;
    color: @black60;
}

.work-acceptance-acceptors__group {
    font-weight: 600;
}

.work-acceptance-acceptors__acceptor {
    font-weight: 500;
}

.work-acceptance-acceptors__remaining-count {
    margin-left: 8px;
    color: @deepBlue;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600
}