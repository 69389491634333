@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.owner-acceptances-page {
	position: relative;

	@media (max-width: @large-mobile-max) {
		padding: 16px 0 0;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}

		@media (max-width: @large-mobile-max) {
			margin-bottom: 16px;
			padding: 0;
			margin-left: 6px;
		}
	}

	&__filters {
		display: flex;
		flex-direction: column;

		@media (max-width: @large-tablet-max) {
			flex-direction: row-reverse;
			justify-content: space-between;
		}

		.entities-filters {
			margin-bottom: 0;
		}

		&-bar {
			.mobile-filters-link-btn {
				height: 40px;
			}

			@media (max-width: @large-tablet-max) {
				margin-bottom: 12px;
				display: flex;
				justify-content: flex-start;
			}

			@media (max-width: @large-mobile-max) {
				padding: 0 16px;
				display: flex;
				justify-content: flex-start;
			}
		}

		.page-header__sub-title {
			@media (max-width: @large-tablet-max) {
				font-size: 14px;
			}
		}
	}

	&__loading-panel {
		min-height: 100%;
	}

	&__header {
		margin-bottom: 16px;

		@media (max-width: @large-mobile-max) {
			padding: 0 16px;
		}

		&-wrap {
			flex-wrap: wrap;
		}

		&-left {
			align-self: flex-start;
		}

		&-right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			flex: 1 1;
			margin-bottom: 10px;
		}

		&-btn {
			margin: 14px 0;

			&:not(:first-child) {
				margin-left: 28px;
			}
		}

		&-add-report-btn {
			margin: 3px 0;

			&:not(:first-child) {
				margin-left: 28px;
			}
		}

		&-add-report-icon {
			width: 12px;
			height: auto;

			path {
				fill: @white;
				stroke: @white;
			}
		}
	}

	&__tabs-links {
		margin-bottom: 30px;
	}

	&__mobile-cell {
		color: @black4;

		&-info {
			display: flex;
			padding: 0 6px;
		}

		&-select {
			padding-right: 18px;
		}

		&-number {
			.d-semibold-14();
		}

		&-status {
			.d-regular-12();
			font-weight: 600;
			margin-top: 4px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -moz-box;
			-moz-box-orient: vertical;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			line-clamp: 2;
			box-orient: vertical;
		}

		&-resolution {
			.d-regular-12();
			margin-top: 4px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -moz-box;
			-moz-box-orient: vertical;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			line-clamp: 2;
			box-orient: vertical;

			&-title {
				color: @black60;
				margin-right: 8px;
			}
		}

		&-space-owner {
			&-label {
				.d-regular-12();
				color: @black60;
			}

			&-container {
				.d-regular-12();
				color: @black4;
				display: flex;
				row-gap: 5px;
			}
		}

		&-created {
			margin-top: 8px;
			.d-regular-12();
			display: flex;
			flex-direction: column;

			&-label {
				color: @black60;
			}

			&-container {
				display: flex;
				color: @black4;
			}

			&-date {
				width: fit-content;
			}

			&-name {
				margin-left: 8px;
				width: fit-content;
			}
		}

		&-location {
			margin-top: 8px;
			.d-regular-12();
			display: flex;
			flex-direction: column;

			&-label {
				color: @black60;
			}

			&-container {
				display: flex;
				color: @black4;
			}
		}

		&-object {
			margin-top: 8px;
			.d-semibold-12();
		}
	}

	&__m-table {
		&-location {
			&-object {
				.d-semibold-14();
				color: @black4;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
			}

			&-block {
				.d-semibold-12();
				margin-top: 8px;
			}

			&-line {
				color: @black4;
				display: flex;
			}

			&-title {
				color: @black60;
				min-width: 50px;
			}

			&-subtitle {
				width: 64px;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
	}

	&__d-table {
		&-number {
			.d-semibold-14();
			color: @black4;
		}

		&-menu {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}

		&-status {
			font-weight: 600;
		}

		&-menu {
			margin: 0;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: transparent;
			transition: background-color 0.2s, opacity 0.2s ease;
			visibility: hidden;
			opacity: 0;

			.tz-delete {
				color: @red50;
			}

			&-wrap {
				display: flex;
				justify-content: space-between;
			}
		}
	}

	& .table2__row_clickable {
		&:hover .owner-acceptances-page__d-table-menu {
			visibility: visible;
			opacity: 1;
			background-color: @buttonLightHover;
		}

		&:active .owner-acceptances__d-table-menu {
			background-color: @buttonLightPressed;
		}
	}
}
