.virtualizer {
    &__scroll-container {
        overflow-y: auto;
        width: 100%;
        contain: 'strict';
    }

    &__track {
        width: 100%;
        position: relative;
    }

    &__list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%
    }
}